<template>
  <form @submit.prevent="onsubmit()">
    <div class="row">
      <va-notification class="flex md10" v-if="currState=='success'" color="success">
        {{ currMsg }}
      </va-notification>
      <va-notification class="flex md10" v-if="currState=='error'" color="danger">
        {{ currMsg }}
      </va-notification>
    </div>

    <br/>

    <div class="auth-layout__options d-flex align--center justify--space-between">
      {{ $t('auth.registerPwdNew') }}<br/>
      {{ $t('auth.pwdRequirements') }}
    </div>

    <br/>

    <va-input
      v-model="email"
      type="email"
      :label="$t('auth.email')"
      :error="!!emailErrors.length"
      :error-messages="emailErrors"
    />

    <va-input
      v-model="password"
      type="password"
      :label="$t('auth.password')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />

    <va-input
      v-model="password2"
      type="password"
      :label="$t('auth.password2')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />

    <!--
      <router-link class="ml-1 link" :to="{name: 'recover-password'}">
        {{$t('auth.recover_password')}}
      </router-link>
    -->

    <div v-if="isChecking" class="d-flex justify--center mt-3">
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#000000"
      />
    </div>
    <div v-else class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">{{ $t('auth.resetPassword') }}</va-button>
    </div>
  </form>
</template>

<script>
import { LoopingRhombusesSpinner } from 'epic-spinners'

export default {
  name: 'set-new-password',
  components: {
    LoopingRhombusesSpinner,
  },
  data () {
    return {
      email: '',
      password: '',
      password2: '',
      resetToken: '',
      emailErrors: [],
      passwordErrors: [],
      currState: '',
      currMsg: '',
      isChecking: false,
      timeoutID: '',
    }
  },
  mounted: function () {
    this.email = this.$route.query.userEmail
    this.resetToken = this.$route.query.pwdResetToken
    // console.log(this.email)
    if ((!this.email) || (!this.resetToken)) {
      this.$router.push('login')
    }
  },
  methods: {
    onsubmit () {
      this.emailErrors = this.email ? [] : [this.$t('auth.errEmailRequired')]
      if (this.password && this.password2) {
        if (this.password === this.password2) {
          if ((this.password.length >= 6) && /[a-z]/.test(this.password) && /[A-Z]/.test(this.password)) {
            this.passwordErrors = []
          } else {
            this.passwordErrors = [this.$t('auth.pwdRequirements')]
          }
        } else {
          this.passwordErrors = [this.$t('auth.errPasswordNotEqual')]
        }
      } else {
        this.passwordErrors = [this.$t('auth.errPasswordRequired')]
      }
      if (!this.formReady) {
        return
      }

      this.isChecking = true

      this.timeoutID = setTimeout(this.loginTimeout, 5000)

      this.$userauth.changePasswd({
        email: this.email,
        password: this.password,
        resetToken: this.resetToken,
      })
        .then(result => {
          clearTimeout(this.timeoutID)
          if (result.userData.status === 'OK') {
            this.currMsg = this.$t('auth.okPwdChange')
            this.currState = 'success'
            this.timeoutID = setTimeout(this.gotoLogin, 4000)
          } else {
            this.currMsg = this.$t('auth.errPwdChangeFailed')
            this.currState = 'error'
            this.isChecking = false
          }
          this.resetRegisterForm()
        })
        .catch(err => {
          console.log(err)
          this.setRegFailMessage()
        })
    },
    setRegFailMessage () {
      clearTimeout(this.timeoutID)
      this.currMsg = this.$t('auth.errPwdChangeFailed')
      this.currState = 'error'
      this.isChecking = false
      this.resetRegisterForm()
    },
    resetRegisterForm () {
      this.email = ''
      this.password = ''
      this.password2 = ''
      this.resetToken = ''
    },
    loginTimeout () {
      this.isChecking = false
      this.currMsg = this.$t('auth.errTimeout')
      this.currState = 'error'
    },
    gotoLogin () {
      this.isChecking = false
      this.$router.push('login')
    },
  },
  computed: {
    formReady () {
      return !(this.emailErrors.length || this.passwordErrors.length)
    },
  },
}
</script>

<style lang="scss">
</style>
